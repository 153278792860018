// TODO: 051823 Move this to services folder.
// TODO: 062223 Implement dependency visualizer. https://github.com/projectstorm/react-diagrams
import { ValidateContext } from "./dependencySelector";
import * as config from "../../services/config";
import * as utils from "../../services/utilities";

// PURPOSE: Functions to manage question/choice dependencies
export function getType(dependency) {
  // PURPOSE: Determine the type of dependency (question or choice)
  utils.log.debug("dependency.getType(dependency)", dependency);
  let result = "none";
  if (dependency?.question != null && dependency?.choice == null) {
    if (dependency?.category != null) result = "category";
    else result = "question";
  }
  if (dependency?.question != null && dependency?.choice != null) {
    if (dependency?.category != null) result = "categoryChoice";
    else result = "choice";
  }
  utils.log.debug(`result: ${result}`);
  return result;
}
export function createBase(question, choice) {
  // Question dependency (common to all question types)
  const dependency = {
    question: question,
    type: "question", // Default type
  };
  // Choice dependency (only for questions with choices)
  if (choice !== undefined && question.choices !== undefined)
    dependency.choice = choice;
  // Allow creation of choice dependency if question has choices

  dependency.type = getType(dependency); // Dependency types: question, choice, none
  return dependency;
}
export function create(question, choice, category) {
  // PURPOSE: Create a dependency object
  const dependency = {
    question: question,
  };
  if (choice != null) dependency["choice"] = choice;
  if (category != null) dependency["category"] = category;
  dependency.type = getType({ question: question, choice: choice });
  return dependency;
}

export function get(context) {
  // PURPOSE: Return dependency from context question or choice
  utils.log.debug("dependency.get(context)", context);
  // #region Assertions
  utils.assert(context, "context is required");
  // #endregion
  // TODO: Convert to retrieving dependency directly from survey.questions.

  switch (context.type) {
    case "question":
      return context.question.dependency;

    case "choice":
      return create(
        context.choice.dependency?.question,
        context.choice.dependency?.choice
      );
    default:
      throw new Error(`dependency.get: Invalid context type(${context.type})`);
  }
}

export function set(context, dependency) {
  // PURPOSE: Assign the dependency to the correct context (question or choice)
  utils.log.debug("dependency.set(context, dependency)", context, dependency);
  // #region Assertions
  utils.assert(context, "context is required");
  utils.assert(
    dependency == null || dependency.type !== undefined,
    "dependency.type is required"
  );
  // #endregion
  // #region *** Initialize ***

  const handleValid = (context, dependency) => {
    switch (context.type) {
      case "question":
        questionSet(context, dependency);
        break;
      case "choice":
        choiceSet(context, dependency);
        break;
      default:
        defaultSet(context);
        break;
    }
  };

  // #endregion

  // #region *** Handle Events ***

  // #endregion
  ValidateContext(context, dependency, handleValid);
}

const questionSet = (context, dependency) => {
  dependency == null
    ? (context.question["dependency"] = null) // Clear question dependency if null
    : (context.question["dependency"] = dependency); // Set question dependency
  if (context.choice !== undefined) context.choice = null; // Clear choice
  utils.log.info("context.question", context.question);
};
const choiceSet = (context, dependency) => {
  context.question["dependency"] = null; // Clear question dependency

  dependency == null
    ? (context.choice["dependency"] = null) // Clear choice dependency if null
    : (context.choice["dependency"] = dependency); // Set choice dependency
};
const defaultSet = (context) => {
  if (context.question !== undefined) context.question["dependency"] = null;
  if (context.choice !== undefined) context.choice["dependency"] = null;
};
/* const isAnswered = (dependency) => {
  // PURPOSE: Check if question is answered
  switch (dependency.type) {
    case "question":
      return config.typeLookup(dependency.question.type).isAnswered(dependency);
    case "choice":
      return config.typeLookup(dependency.question.type).isAnswered(dependency);
    default:
      throw new Error(
        `isAnswered: Invalid dependency type(${dependency.type})`
      );
  }
}; */
export function getDependents() {
  // PURPOSE: Return the questions and choices that depend on this question or choice.
  debugger;
}
export function getDependency(dependency) {
  // PURPOSE: Gets the question/choice from the dependency object.
  if (dependency == null) return null;
  utils.log.debug(`getDependency(type: ${dependency.type})`, dependency);
  const type = getType(dependency);
  switch (type) {
    case "question":
      return dependency.question;
    case "choice":
      return dependency.choice;
    case "category":
      return dependency.question.answer;
    case "categoryChoice":
      return dependency.question.answer;
    default:
      throw new Error(
        `getDependency: Invalid dependency type(${dependency.type})`
      );
  }
}
export const resolveDependency = (questions, dependencySource) => {
  // !KLUDGE: Returns correct references to dependencies.
  utils.assert(questions != null, "questions can not be null.");
  utils.assert(
    Array.isArray(questions) &&
      questions.every((obj) => typeof obj === "object" && obj !== null),
    "questions is expected to be an array of objects."
  );
  if (dependencySource == null) return; // No dependency
  const question = questions.find(
    (q) => q.code === dependencySource.question.code
  );
  dependencySource.question = question;
  switch (dependencySource.type) {
    case "question":
      return dependencySource;
    /*       return {
        question: question,
        type: dependencySource.type,
        isAnswered: dependencySource.isAnswered,
      }; */

    case "choice":
      const choice = question.choices.find(
        (c) => c.code === dependencySource.choice.code
      );

      utils.log.info(
        "resolveDependency(): choice.dependents",
        choice.dependents()
      );
      dependencySource.choice = choice;
      return dependencySource;
    /*       const resolved = {
        question: question,
        choice: choice,
        type: dependencySource.type,
        isAnswered: dependencySource.isAnswered,
      }; */

    //return resolved;
    default:
      throw new Error(`Invalid dependency type: ${dependencySource.type}`);
  }
};
export const resolveVisibilities = (questions) => {
  // PURPOSE: Resolve the visibility of the question/choices in the survey.

  utils.log.debug(`Resolve visibility for all questions...`, questions);
  questions.forEach((question, index) => {
    //utils.log.set(["Q4", "Q5"].includes(question.code)); // Turn on logging for this question
    if (index === 0) {
      // First question is always visible
      utils.log.info(
        "resolveVisibilities(): First question is always visible",
        config.cssStyle.questionHeading,
        question
      );
      question.isVisible = true;
      question?.choices?.forEach((choice) => {
        choice.isVisible = true;
      });
    } else {
      resolveQuestionVisibility(question, questions);
    }
  });
};
export const resolveQuestionVisibility = (question, questions) => {
  // PURPOSE: Resolve the visibility of the question which is dependent on the dependency.
  // As a question, I am visible if I don't have a dependency or if my dependency is answered.

  utils.log.info(
    `resolveQuestionVisibility(${question.code}): Checking if question(${question.code}) dependency is answered...`,
    config.cssStyle.questionHeading,
    question
  );
  const dependency = resolveDependency(questions, question.dependency);
  const dependencyObject = getDependencyObject(dependency);

  const isDependencyAnswered = !dependency
    ? true // No dependency - always visible
    : config
        .typeLookup(dependencyObject.questionType) // Get isAnswered function for question type
        .isAnswered(dependency); // Check if dependency is answered

  const isVisible = () => {
    return isDependencyAnswered; // Dependency object is visible - check if answered
  };

  utils.log.info(
    dependency == null
      ? `resolveQuestionVisibility(${
          question.code
        }): isVisible(${isVisible()}) <-- (no dependency) <-- isDependencyAnswered(${isDependencyAnswered})`
      : `resolveQuestionVisibility(${
          question.code
        }): isVisible(${isVisible()}) <-- dependency(${
          dependencyObject.code
        }).isVisible(${
          dependencyObject.isVisible
        }) && isDependencyAnswered(${isDependencyAnswered})`
  );
  question.isVisible = isVisible();
  if (question.isVisible && question.choices != null) {
    question.choices.forEach((choice) => {
      resolveChoiceVisibility(choice, questions);
    });
  }
};
const resolveChoiceVisibility = (choice, questions) => {
  //const question = choice.question();
  const choiceValue = choice.valueFormatted?.(".");
  const dependency = resolveDependency(questions, choice.dependency);
  utils.log.debug(
    `resolveChoiceVisibility(${choiceValue}): Checking if choice(${choiceValue}) dependency is answered...`,
    choice
  );
  const dependencyObject = getDependencyObject(dependency);
  const isDependencyAnswered =
    dependency == null
      ? true // No dependency - always visible
      : config
          .typeLookup(dependencyObject.questionType) // Get isAnswered function for question type
          .isAnswered(dependency); // Check if dependency is answered
  //const isAnswered = dependency == null ? true : isQuestionAnswered; //question.isAnswered(dependency);
  //const isVisible = (dependencyObject?.isVisible ?? true) && isAnswered;
  const isVisible = () => {
    if (dependency == null) return true; // No dependency - always visible

    if (!dependencyObject.isVisible) return false; // Dependency object is not visible - always invisible

    return isDependencyAnswered; // Dependency object is visible - check if answered
  };
  utils.log.info(
    dependency == null
      ? `resolveChoiceVisibility(${choiceValue}): isVisible(${isVisible()}) <-- (no dependency) <-- isDependencyAnswered(${isDependencyAnswered})`
      : `resolveChoiceVisibility(${choiceValue}): isVisible(${isVisible()}) <-- dependency(${
          dependencyObject.code
        }).isVisible(${
          dependencyObject.isVisible
        }) && isDependencyAnswered(${isDependencyAnswered})`
  );
  choice.isVisible = isVisible();
};
const getDependencyObject = (dependency) => {
  // PURPOSE: Get the dependency object from the dependency
  utils.log.debug("getDependencyObject(dependency)", dependency);
  if (dependency == null) return null;
  const dependencyObject = getDependency(dependency);
  const dependencyType = getType(dependency);
  /*   const dependencyCode =
    dependencyType === "question"
      ? dependencyObject?.code
      : dependencyObject?.valueFormatted("."); */
  let dependencyCode;

  switch (dependencyType) {
    case "question":
      dependencyCode = dependencyObject?.code;
      break;
    case "choice":
      dependencyCode = dependencyObject?.valueFormatted?.(".");
      break;
    case "category":
      dependencyCode = `${dependency?.question.code}.${dependency.category}`;
      break;
    case "categoryChoice":
      dependencyCode = `${dependency?.question.code}.${dependency?.choice.code}.${dependency.category}`;
      break;
    default:
      throw new Error(`Invalid dependency type: ${dependencyType}`);
  }

  /*   const questionType =
    dependencyType === "question"
      ? dependencyObject.type
      : dependencyObject.question().type; */
  let questionType;

  switch (dependencyType) {
    case "question":
      questionType = dependencyObject.type;
      break;
    case "choice":
      questionType = dependencyObject.question().type;
      break;
    case "category":
      questionType = dependency?.question.type;
      break;
    case "categoryChoice":
      questionType = dependency?.question.type;
      break;
    default:
      throw new Error(`Invalid dependency type: ${dependencyType}`);
  }

  const result = {
    object: dependencyObject,
    isVisible: dependencyObject?.isVisible ?? true,
    code: dependencyCode ?? null,
    questionType: questionType,
    dependencyType: dependencyType,
  };
  utils.log.disabled(
    "getDependencyObject(dependency): dependencyObject",
    result
  );
  return result;
};
export function hasDependency(dependency) {}
export function isDependencyObject(dependency) {
  // PURPOSE: Check if dependency is a dependency object
  if (dependency == null) return false;
  // Check if dependency has property question
  if (!dependency.hasOwnProperty("question")) return false;
  return true;
}
