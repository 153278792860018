// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*
ISSUES

*/
import React from "react";
import Input from "../../components/Input/inputs";
import { Radio } from "antd";
import { Typography, Grid, TextField, FormHelperText } from "@mui/material";
import * as utils from "../../services/utilities";
import FormUtility from "../../services/form";
import * as dependencyUtil from "./dependency";
import { QuestionHeader } from "../recipients/question";
import { DependencySelectorComparatorValue } from "./numericInput";
import errorIcon from "../../assets/errorIcons.svg";
// import useMediaQuery from "../../hooks/useMediaQuery";

const formUtility = new FormUtility();

const logicalConnectors = ["AND", "OR"];

export function New() {
  return {
    type: "NumericInputRange",
    code: null,
    title: "New Numeric Input (Range) Question",
    description: "",
    instructions: "",
    dependency: null,
    min: 1,
    max: 10,
  };
}
export function Reset() {
  // PURPOSE: Reset properties specific to this question type.
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // #region Initialize
  question.startMin =
    !question?.startMin || question.startMin < 1 ? 1 : question.startMin;
  question.startMax =
    !question?.startMax || question.startMax < 1 ? 10 : question.startMax;
  question.endMin =
    !question?.endMin || question.endMin < 1 ? 1 : question.endMin;
  question.endMax =
    !question?.endMax || question.endMax < 1 ? 10 : question.endMax;
  formUtility.setDetail(question);


  // #endregion
  // #region Events

  const handleMinChange = (e) => {
    const value = e.target.value;
    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Minimum must be a number.", { variant: "error" });
    else {
      // Valid value
      question.min = Number(value);
    }
    question.min = Number(value);
  };
  const handleMaxChange = (e) => {
    const value = e.target.value;
    const min = question.min;

    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Maximum must be a number.", { variant: "error" });
    else if (value < min)
      // Greater than max
      return enqueueSnackbar("Maximum must be greater than maximum.", {
        variant: "error",
      });
    // Valid value
    else {
      question.maxChoices = Number(value);
    }

    question.maxChoices = Number(value);
  };

  const handleChange = (e) => {
    if (e.target.name === "startMin") {
      if (e.target.value > Number(question?.startMax)) {
        return enqueueSnackbar(
          "Start Minimum must be smaller than or equal to Start Maximum",
          {
            variant: "error",
          }
        );
      }
    }

    if (e.target.name === "endMin") {
      if (e.target.value > Number(question?.endMax)) {
        return enqueueSnackbar(
          "End Minimum must be smaller than or equal to End Maximum",
          {
            variant: "error",
          }
        );
      }
    }
    formUtility.handleChange(e, setInputs);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            id="non-linear-slider"
            gutterBottom
            style={{ fontFamily: "Public-sans" }}
          >
            Range:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            id="non-linear-slider"
            gutterBottom
            style={{ fontFamily: "Public-sans" }}
          >
            Start
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            label="Minimum"
            fullWidth
            name="startMin"
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMinChange}
            value={formUtility.getValue("startMin")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            label="Maximum"
            fullWidth
            name="startMax"
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMaxChange}
            value={formUtility.getValue("startMax")}
          />
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography
            id="non-linear-slider"
            gutterBottom
            style={{ fontFamily: "Public-sans" }}
          >
            End
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            label="Minimum"
            fullWidth
            name="endMin"
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMinChange}
            value={formUtility.getValue("endMin")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Input
            label="Maximum"
            fullWidth
            name="endMax"
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMaxChange}
            value={formUtility.getValue("endMax")}
          />
        </Grid>
      </Grid>
    </>
  );
}
export const DependencySelector = ({ context, selectedQuestion }) => {
  // !NOTE: useState() is prohibited in this component.
  // #region Initialize
  const dependency = dependencyUtil.get(context);

  if (dependency != null && dependency?.value == null)
    // Add a value if it doesn't exist
    dependency.value = null;
  // #endregion
  // #region Events
  const handleLogicalConnectorChange = ({ target: { value } }) => {};
  // #endregion
  return (
    <>
      <hr></hr>
      <Grid container spacing="20">
        <Grid item xs={12}>
          <DependencySelectorComparatorValue
            id="startValue"
            label="Start"
            context={context}
          ></DependencySelectorComparatorValue>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            justifyContent: "center",
            backgroundColor: "#F1F4F5",
            paddingBottom: "20px",
          }}
        >
          <Radio.Group
            options={logicalConnectors}
            onChange={handleLogicalConnectorChange}
            value={logicalConnectors[0]}
            optionType="button"
            buttonStyle="solid"
          />
        </Grid>
        <Grid item xs={12}>
          <DependencySelectorComparatorValue
            id="endValue"
            label="End"
            context={context}
          ></DependencySelectorComparatorValue>
        </Grid>
      </Grid>
    </>
  );
};
export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  utils.log.component("NumericInputRange.Render()", props);
  // #region Initialize
  const question = props.question;
  const startMinValue = question.startMin ?? 0;
  const startMaxValue = question.startMax ?? 0;
  const endMinValue = question.endMin ?? 0;
  const endMaxValue = question.endMax ?? 0;
  const start = question.start ?? startMinValue;
  const end = question.end ?? endMinValue;
  const preview = props.preview ?? false;
  const startValidationMessages =
    start < startMinValue || start > startMaxValue
      ? `Start value must be between ${startMinValue} and ${startMaxValue}.`
      : "";

  const endValidationMessages =
    end < endMinValue || end > endMaxValue
      ? `End value must be between ${endMinValue} and ${endMaxValue}.`
      : "";

  // #endregion
  // #region Events
  const handleStartValueChange = (e) => {
    if (preview) {
      props.setQuestions((question) => {
        return { ...question, start: Number(e.target.value) };
      });
    } else {
      props.setQuestions((prevQuestions) => {
        let updatedQuestions = prevQuestions.map((prevQuestion) => {
          if (prevQuestion.code === question.code) {
            return { ...prevQuestion, start: Number(e.target.value) };
          } else return prevQuestion;
        });
        return updatedQuestions;
      });
    }
  };

  const handleEndValueChange = (e) => {
    if (preview) {
      props.setQuestions((question) => {
        return { ...question, end: Number(e.target.value) };
      });
    } else {
      props.setQuestions((prevQuestions) => {
        let updatedQuestions = prevQuestions.map((prevQuestion) => {
          if (prevQuestion.code === question.code) {
            return { ...prevQuestion, end: Number(e.target.value) };
          } else return prevQuestion;
        });
        return updatedQuestions;
      });
    }
  };
  const isQuestionCompleted = isCompleted(question);

  // const {isSmDown , isMdDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            Q{props.index + 1}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          {/* <div>{helperText}</div> */}
          <div
            // style={{ display: "flex", gap: "30px",flexDirection:isMdDown ? "column" : "row" }}>
            style={{ display: "flex", gap: "30px", flexDirection: "row" }}
          >
            <TextField
              id="textInput-startValue"
              label={`Start Number (Range ${startMinValue} to ${startMaxValue})`}
              name="startValue"
              style={{ width: "300px", background: "#F8F8F8" }}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                // min: startMinValue,
                // max: startMaxValue,
              }}
              placeholder={`Start Number (Range ${startMinValue} to ${startMaxValue})`}
              onChange={handleStartValueChange}
            ></TextField>
            <TextField
              id="textInput-endValue"
              label={`End Number (Range ${endMinValue} to ${endMaxValue})`}
              name="endValue"
              style={{ width: "300px", background: "#F8F8F8" }}
              type="number"
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                // min: endMinValue,
                // max: endMaxValue,
              }}
              placeholder={`End Number (Range ${endMinValue} to ${endMaxValue})`}
              onChange={handleEndValueChange}
            ></TextField>
          </div>
        </div>
      </div>
      {startValidationMessages ? (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop: preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            {startValidationMessages}
          </FormHelperText>
        </div>
      ) : (
        endValidationMessages && (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              gap: "10px",
              marginTop: preview ? 0 : "-20px",
            }}
          >
            <img
              src={errorIcon}
              alt="error-icon"
              height={"15px"}
              width={"15px"}
            />
            <FormHelperText style={{ color: "#DB3B3B" }}>
              {endValidationMessages}
            </FormHelperText>
          </div>
        )
      )}
    </>
  );
}
// #region Dependency
export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  //debugger;
  if (dependency == null) {
    utils.log.info(`NumericInputRange.isAnswered(false): dependency is null`);
    return false;
  }
  return false;
}

export const isCompleted = (question) => {
  if (!question) {
    utils.log.warn("NumericInputRange.isCompleted(false): question is null");
    return false;
  }

  const { start, end, startMin, startMax, endMin, endMax } = question;

  // Check if the start and end values are set
  // And are between there respective min and max values

  if (start == null || end == null) {
    return false;
  }

  if (start < startMin || start > startMax) {
    return false;
  }

  if (end < endMin || end > endMax) {
    return false;
  }

  return true;
};

export const getValue = (question) => {
  return {
    start: question.start,
    end: question.end,
  };
};

// #endregion
/*
FIXED

*/
