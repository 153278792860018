// !DEPRECATED (042623) - Use cache.js going forward (when it is done).
import * as utils from "./utilities.js";
export default class FormUtility {
  constructor() {
    this.detail = null;
    this.inputs = null;
    this.services = null;
  }
  handleClose() {
    this.onClose();
  }
  handleChange(event, setInputs) {
    utils.log.info("FormUtility.handleChange", event);
    const name = event.target.name;
    let value = event.target.value;
    const type = event.target.type;
    // handling empty string
    if (type === "number") {
      value = value === "" ? "" : Number(value);
    }
    utils.assert(value != null, "FormUtility.handleChange - value is null.");
    utils.log.info(`FormUtility.handleChange - name: ${name} value: ${value}`);
    if (this.detail != null) this.detail[name] = value;
    setInputs((values) => ({ ...values, [name]: value }));
  }
  setValue(name, value) {
    utils.log.info(`FormUtility.setValue - name: ${name} value: ${value}`);
    this.setInputs((values) => {
      debugger;
      return { ...values, [name]: value };
    });
  }
  getValue2(name) {
    utils.log.info(`FormUtility.getValue - name: ${name}`);
    return this.inputs[name];
  }
  getValue(fieldName) {
    utils.log.info(`FormUtility.getValue - fieldName: ${fieldName}`);
    if (this.detail == null) {
      return this?.inputs[fieldName] == null ? "" : this.inputs[fieldName];
    } else {
      return this.detail[fieldName] || "";
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    utils.log.info("FormUtility.handleSubmit", this.detail);
    if (this.detail == null) {
      this.services.add(this.inputs).then((result) => {
        this.handleClose();
      });
    } else {
      this.inputs["id"] = this.detail.id;

      this.services.update(this.detail).then((result) => {
        this.handleClose();
      });
    }
  }

  setDetail(detail) {
    utils.log.info("Detail set.", detail);
    this.detail = detail;
  }
  setInputs(inputs) {
    utils.log.info("Inputs set.", inputs);
    this.inputs = inputs;
  }
  getInputs() {
    utils.log.info("getInputs", this.inputs);
    return this.inputs;
  }
  getSetInputs() {
    utils.log.info("getSetInputs", this.setInputs);
    return this.setInputs;
  }
  setSetInputs(setInputs) {
    utils.log.info("SetInputs set.");
    this.setInputs = setInputs;
  }
  getInputText(name, form) {
    utils.log.debug("getInputText", name, form);
    const textBox = Array.from(form.elements).find(
      (element) => element.type === "text" && element.name === name
    );

    return textBox;
  }
  clearErrorObject(name) {
    this.setErrorObject(name);
  }
  setErrorObject(name, state = false, message = null) {
    const errorObject = { name: name, state: state, message: message };
    this.setInputs((values) => ({ ...values, [`error-${name}`]: errorObject }));
    utils.log.info("setErrorObject", errorObject);
    return errorObject;
  }
  getErrorObject(name) {
    const errorObject = this.inputs[`error-${name}`] ?? null;
    return errorObject;
  }
}
